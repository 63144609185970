import { FC } from "react"
import { Link } from "gatsby"
import { Button, Row, Col, Menu, Typography } from "antd"
import { PhoneOutlined } from "@ant-design/icons"

import FlexContainer from "@root/ic-ui-react/FlexContainer"

const DesktopMenu: FC = () => (
  <Row align="middle">
    <Col span={6}>
      <FlexContainer justifyContent="start">
        <Link to="/">
          <Typography.Title level={4} style={{ marginBottom: "0" }}>
            KING KOI FARM JP
          </Typography.Title>{" "}
        </Link>
      </FlexContainer>
    </Col>

    <Col span={12}>
      <Menu
        mode="horizontal"
        style={{
          display: "flex",
          justifyContent: "space-around",
          lineHeight: "normal",
          borderBottom: "none",
        }}
      >
        <Menu.Item>
          <Link to="/" activeStyle={{ color: "#4e90ff", fontWeight: "500" }}>
            <strong>TRANG CHỦ</strong>
          </Link>
        </Menu.Item>
      </Menu>
    </Col>

    <Col span={6}>
      <FlexContainer justifyContent="flex-end">
        <Button
          type="primary"
          shape="round"
          icon={<PhoneOutlined />}
          size="small"
        >
          THAM KHẢO
        </Button>
      </FlexContainer>
    </Col>
  </Row>
)

export default DesktopMenu
