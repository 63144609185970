import React, { FC, useCallback, useState } from "react"
import { Drawer, Menu, Typography, Button, Row, Col, Space } from "antd"
import { Link } from "gatsby"
import { PhoneOutlined, MenuOutlined } from "@ant-design/icons"

const MobileMenu: FC<{ placement: "top" | "bottom" | "right" | "left" }> = ({
  placement,
}) => {
  const [visible, setVisible] = useState(false)

  const handleToggleMenu = useCallback(() => {
    setVisible(visible => !visible)
  }, [])

  const handleCloseMenu = useCallback(() => {
    setVisible(false)
  }, [])

  return (
    <Row
      justify="space-between"
      align="middle"
      style={{
        backgroundColor: "white",
      }}
    >
      <Col span={18}>
        <Link to="/">
          <Typography.Title level={4} style={{ marginBottom: "0" }}>
            KING KOI FARM JP
          </Typography.Title>
        </Link>
      </Col>

      <Col span={6}>
        <Button
          type="text"
          icon={<MenuOutlined />}
          onClick={handleToggleMenu}
          block
        >
          {" "}
          MENU
        </Button>
      </Col>

      <Drawer
        title={
          <>
            <Link to="/">
              {/* <Image src={Logo} width="150px" height="150px" /> */}
              <Typography.Title level={4} style={{ marginBottom: "0" }}>
                KING KOI FARM JP
              </Typography.Title>
              <Typography.Paragraph type="secondary">
                Cá Koi Chất Lượng Cao <br />
                Nhập Khẩu Từ Nhật Bản
              </Typography.Paragraph>
            </Link>

            <Space wrap>
              <Button
                type="primary"
                icon={<PhoneOutlined size={18} />}
                href="tel:5624240446"
                size="small"
              >
                THAM KHẢO
              </Button>{" "}
            </Space>
          </>
        }
        placement={placement}
        onClose={handleCloseMenu}
        visible={visible}
        key={placement}
        width="100%"
        height="auto"
      >
        <Menu style={{ width: "100%" }} mode="vertical">
          <Menu.Item key="/">
            <Link to="/" activeStyle={{ color: "#4e90ff", fontWeight: "500" }}>
              <strong>TRANG CHỦ</strong>
            </Link>
          </Menu.Item>
        </Menu>
      </Drawer>
    </Row>
  )
}

export default MobileMenu
