import styled from "styled-components"

interface FlexProps {
  direction?: "row" | "row-reverse" | "column" | "column-reverse"
  wrap?: "nowrap" | "wrap" | "wrap-reverse"
  justifyContent?:
    | "start"
    | "flex-start"
    | "end"
    | "flex-end"
    | "center"
    | "space-between"
    | "space-around"
    | "space-evenly"
  alignContent?:
    | "start"
    | "end"
    | "center"
    | "space-between"
    | "space-around"
    | "space-evenly"
  justifyItems?: "start" | "end" | "center" | "stretch"
  alignItems?: "start" | "end" | "center" | "stretch"
}

const FlexContainer = styled.div<FlexProps>`
  display: flex;
  flex-direction: ${props => props.direction};
  justify-content: ${props => props.justifyContent};
  justify-items: ${props => props.justifyItems};
  align-items: ${props => props.alignItems};
  align-content: ${props => props.alignContent};
  flex-wrap: ${props => props.wrap};
`

export default FlexContainer
