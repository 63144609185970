/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import { Layout } from "antd"
import React, { FC } from "react"
import styled from "styled-components"

import { ThemeProvider, palette } from "@ic-ui-react/theme"

import Footer from "./Footer"
import Header from "./Header"
import SiteWideNotice from "./SiteWideNotice"

const { Content } = Layout

const StyledContent = styled(Content)`
  background-color: ${palette.backgroundColor}!important;
  margin-top: 0.5px;
`
const MainLayout: FC = ({ children }) => (
  <ThemeProvider>
    <Layout>
      <Header />
      <StyledContent>{children}</StyledContent>

      <Footer />
    </Layout>
  </ThemeProvider>
)

export default MainLayout
