import { FacebookOutlined, YoutubeFilled } from "@ant-design/icons"
import { Col, Layout, Row, Typography, Button } from "antd"
import React, { FC } from "react"

import ZaloLogo from "@images/zalo-logo.svg"

const FooterWrapper: FC = () => (
  <Layout.Footer className="shadow-sm bg-light">
    <Row align="middle" justify="space-between" gutter={[32, 12]}>
      <Col xs={24} sm={24} md={8} lg={8} xl={8}>
        <Row align="middle">
          <Button
            type="link"
            href="https://www.facebook.com/Tr%E1%BA%A1i-C%C3%A1-Koi-Ch%E1%BA%A5t-L%C6%B0%E1%BB%A3ng-KING-KOI-FARM-JP-114103460450767"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FacebookOutlined style={{ fontSize: "46px" }} />
          </Button>
          <Button
            type="link"
            href="https://www.youtube.com/channel/UCP72Boh8xARED3J6cNj7mlQ"
            target="_blank"
            rel="noopener noreferrer"
          >
            <YoutubeFilled style={{ fontSize: "46px" }} />
          </Button>
          <Button
            type="link"
            href="https://www.zalo.me/0898947888"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={ZaloLogo} width="46px" height="auto" alt="zalo logo" />
          </Button>
        </Row>
      </Col>

      <Col xs={24} sm={24} md={8} lg={8} xl={8}>
        <Typography.Title
          level={5}
          style={{ textAlign: "end", marginBottom: 0 }}
        >
          ©{new Date().getFullYear()} KING KOI FARM JP
        </Typography.Title>
        <Typography.Paragraph
          type="secondary"
          style={{ textAlign: "end", marginBottom: 0 }}
        >
          Made by{" "}
          <a href="https://www.ingeniumconcepts.io">Ingenium Concepts</a>
        </Typography.Paragraph>
      </Col>
    </Row>
  </Layout.Footer>
)
export default FooterWrapper
